<template>
  <q-dialog
    v-model="isOpen"
    persistent
    maximized
  >
    <q-card>
      <q-card-section class="row items-center q-py-sm">
        <div class="text-h6">
          {{ $t('Measure product') }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section v-if="product">
        <product-object :data="product" />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section>
        <barcode-input
          :has-max-width="true"
          :settings="terminalSettings"
          :out-focused="outFocused"
          @barcode="handleValue"
        />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="negative"
          :label="$t('Cancel')"
          no-caps
          @click="handleClose"
        />

        <q-btn
          color="positive"
          :label="$t('Save')"
          no-caps
          @click="handleOk"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import BarcodeInput from './../barcode-input/BarcodeInput.vue'

export default {
  name: 'DimensionsModal',
  emits: ['on-submit', 'show', 'hide'],
  components: {
    BarcodeInput
  },
  data () {
    return {
      isOpen: false,
      outFocused: false,
      model: {
        x: 0,
        y: 0,
        z: 0,
        weight: 0
      }
    }
  },
  computed: {
    ...mapGetters([
      'terminalSettings'
    ]),
    schema () {
      return {
        product: null,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'number',
                value: this.model.x,
                label: this.$t('X'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                required: true,
                onFocus: () => {
                  this.handleFocus()
                },
                onFocusOut: () => {
                  this.handleFocusOut()
                },
                onChange: x => {
                  this.model.x = x
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.model.y,
                label: this.$t('Y'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                required: true,
                onFocus: () => {
                  this.handleFocus()
                },
                onFocusOut: () => {
                  this.handleFocusOut()
                },
                onChange: y => {
                  this.model.y = y
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.model.z,
                label: this.$t('Z'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                required: true,
                onFocus: () => {
                  this.handleFocus()
                },
                onFocusOut: () => {
                  this.handleFocusOut()
                },
                onChange: z => {
                  this.model.z = z
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.model.weight,
                label: this.$t('Weight'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                onFocus: () => {
                  this.handleFocus()
                },
                onFocusOut: () => {
                  this.handleFocusOut()
                },
                onChange: weight => {
                  this.model.weight = weight
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleValue (barcode) {
      const actions = {
        X: value => {
          this.model.x = value
        },
        Y: value => {
          this.model.y = value
        },
        Z: value => {
          this.model.z = value
        },
        DB: value => {
          const setters = {
            0: (v) => {
              this.model.weight = v
            },
            1: (v) => {
              this.model.x = v
            },
            2: (v) => {
              this.model.y = v
            },
            3: (v) => {
              this.model.z = v
            }
          }

          value.split('/').forEach((value, index) => {
            if (value && value > 0) {
              setters[index](value)
            }
          })
        }
      }

      if (actions[barcode.type]) {
        actions[barcode.type](barcode.value)
      } else {
        this.model.weight = barcode.value
      }

      console.debug(this.hasInvalidValue())

      if (this.hasInvalidValue()) {
        return
      }

      this.handleOk()
    },
    hasInvalidValue () {
      return Object.values(this.model).find(x => Number(x) <= 0) !== undefined
    },
    handleFocus () {
      this.outFocused = true
    },
    handleFocusOut () {
      this.outFocused = false
    },
    open (product) {
      this.product = product
      this.model = {
        x: 0,
        y: 0,
        z: 0,
        weight: 0
      }

      this.isOpen = true
      this.$emit('show')
    },
    handleClose () {
      this.isOpen = false
      this.$emit('hide')
    },
    handleOk () {
      if (this.hasInvalidValue()) {
        this.addErrorNotification('All values must be more than 0!')
        return
      }

      const data = {
        dimensions: {
          x: this.model.x,
          y: this.model.y,
          z: this.model.z
        },
        weight: this.model.weight
      }

      this.$emit('on-submit', data)
      this.handleClose()
    }
  }
}
</script>
